import React, { useContext, useState, useEffect } from 'react';
import { DataContext } from '../Services/DataContext';
import MainLayout from '../Layouts/MainLayout';
import SideBar from '../Layouts/SideBar';
import CustomList from '../Layouts/CustomList';

function Subscription() {
    const { subscriptions, allSubscriptions } = useContext(DataContext);
    const [loading, setLoading] = useState(true); // État de chargement
    const barParams = { nav1: 'Date', nav2: 'Nom Produit', nav3: 'N° Commande', nav4: 'Prix', nav5: 'Status' };
    const titleItem = { name: "Co-abonnements", title1: "Subscription", title2: "Nombre de Subscription", title3: "Subscription", title4: "Subscription" };
    const linkItem = { link1: "#", link2: "#", link3: "#", link4: "#" };
    const linkList = { show: `/dashboard/subscription/show-subscription-id`, edit: "/dashboard/subscription/edit-subscription" };
    const [seeMorSubs , setSeeMoreSubs] = useState(false)

    useEffect(() => {
        // Simuler un délai pour le chargement des données
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000); // Simule un délai de 1 seconde

        return () => clearTimeout(timer);
    }, []);

    //filter the duplicates
    // const filterDuplicates = (array) => {
    //     const hash = {};
    //     return array.filter(item => {
    //         if (!hash[item.subscriptionRef]) {
    //             hash[item.subscriptionRef] = true;
    //             return true;
    //         }
    //         return false;
    //     });
    // };

    // const filteredArray = filterDuplicates(subscriptions);
    const allSubs = () => {
        setSeeMoreSubs(true)
    }
    return (
        <div className='Product-layout'>
            <SideBar />
            <MainLayout
                titleItem={titleItem}
                linkItem={linkItem}
                numberItem={seeMorSubs ? allSubscriptions.length : subscriptions.length}
            />
            {loading ? (
                <div className="loading-spinner">En cours...</div> // Ajoute ici ton spinner ou un indicateur de chargement
            ) : (
                <CustomList
                    barParams={barParams}
                    listItems={seeMorSubs ? [...allSubscriptions].reverse() : [...subscriptions]}
                    linkList={linkList}
                    key1="createdAt"
                    key2="subscriptionName"
                    key3="subscriptionRef"
                    key4="subscriptionPrice"
                    key5="subscriptionStatus"
                    idKey="_id"
                    urlDelete="delete-subscription"
                    seeMore="/dashboard/subscription/details-subs"
                    classSeeMore="classSeeMoreActive"
                    btnExportData="btn-export-data"
                />
                
            )}
            <p onClick={() => allSubs()} id='see-more-subs'>Plus de Commandes</p>
            </div>
    );
}

export default Subscription;
