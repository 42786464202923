import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Instance from '../Services/Instance';
import config from "../Services/config.js";
import show from "../Assets/Imgs/icon-show.svg";
import edit from "../Assets/Imgs/icon-edit.svg";
import iconDelete from "../Assets/Imgs/icon_delete.svg";
import "../Assets/Styles/Dasboard.css";
import { saveAs } from 'file-saver'; // Importez file-saver
import Papa from 'papaparse'; // Importez papaparse

const CustomList = (props) => {
    const { barParams, listItems, linkList, key1, key2, key3, key4, key5, idKey, urlDelete, seeMore, classSeeMore,btnExportData } = props;

    const [searchTerm, setSearchTerm] = useState("");
    const [searchTerm2, setSearchTerm2] = useState("");

    async function deleteProduct(id, url) {
        const confirmation = window.confirm("Voulez-vous vraiment supprimer cet élément ?");
        if (!confirmation) {
            return;
        }

        try {
            await Instance.delete(`${config.api_url}/${url}/${id}`);
            window.location.reload();
        } catch (error) {
            console.error("Erreur lors de la suppression de l'élément :", error);
        }
    }

    const formattedDate = (_date) => {
        const dateObject = new Date(_date);
        return dateObject.toLocaleDateString('fr-FR');
    };

    const convertChar = (emailString) => {
        if (emailString) {
            const index = emailString.indexOf("undefined") + "undefined".length + 2;
            if (index > 1) {
                const extractedString = emailString.substring(index);
                return extractedString;
            }
        }
        return emailString;
    };

    const isValidDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        const date = new Date(`${year}-${month}-${day}`);
        return date instanceof Date && !isNaN(date);
    };

    const formatDate = (date) => {
        const d = new Date(date);
        let day = d.getDate();
        let month = d.getMonth() + 1;
        const year = d.getFullYear();

        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return `${day}/${month}/${year}`;
    };

    // Premier filtrage avec searchTerm
    const filteredItems = listItems.filter(item => {
        const search = searchTerm.toLowerCase();

        if (isValidDate(searchTerm)) {
            const formattedSearchDate = formatDate(searchTerm);
            const formattedItemDate = formatDate(item[key1]);

            return formattedItemDate === formattedSearchDate;
        }

        return (
            item[key1]?.toString().toLowerCase().includes(search) ||
            item[key2]?.toString().toLowerCase().includes(search) ||
            item[key3]?.toString().toLowerCase().includes(search) ||
            item[key4]?.toString().toLowerCase().includes(search) ||
            (item[key5] && item[key5].toString().toLowerCase().includes(search))
        );
    });

    // Deuxième filtrage avec searchTerm2 sur les résultats filtrés précédemment
    const doubleFilteredItems = filteredItems.filter(item => {
        const search2 = searchTerm2.toLowerCase();

        return (
            item[key1]?.toString().toLowerCase().includes(search2) ||
            item[key2]?.toString().toLowerCase().includes(search2) ||
            item[key3]?.toString().toLowerCase().includes(search2) ||
            item[key4]?.toString().toLowerCase().includes(search2) ||
            (item[key5] && item[key5].toString().toLowerCase().includes(search2))
        );
    });

    // Fonction pour exporter les données au format CSV
    const exportToCSV = () => {
        // Créez un tableau contenant les clés et valeurs à exporter
        const csvData = listItems.map(item => ({
            [barParams.nav1]: item[key1] && item[key1].includes(":") ? formattedDate(item[key1]) : item[key1],
            [barParams.nav2]: item[key2],
            [barParams.nav3]: item[key3],
            [barParams.nav4]: item[key4],
            [barParams.nav5]: item[key5] ? convertChar(item[key5]) : ''
        }));

        // Convertir les données en CSV
        const csv = Papa.unparse(csvData);

        // Créer un blob avec le contenu CSV et le type de fichier
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });

        // Utiliser file-saver pour télécharger le fichier
        saveAs(blob, "Data.csv");
    };

    return (
        <div className='custom-list'>
            <div className='list-elements nav-bar-list'>
                <ul>
                    <li>{barParams.nav1}</li>
                    <li>{barParams.nav2}</li>
                    <li>{barParams.nav3}</li>
                    <li>{barParams.nav4}</li>
                    <li>{barParams.nav5}</li>
                </ul>
                <div className='actions'>
                    <p>Actions</p>
                </div>
            </div>
            <div className="search">
                <input
                    type="text"
                    placeholder='Recherche...'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <input
                    type="text"
                    placeholder='Recherche...'
                    value={searchTerm2}
                    onChange={(e) => setSearchTerm2(e.target.value)}
                />
                {/* Ajout du bouton pour exporter les données */}
                <button className="export-btn" id={`${btnExportData}`} onClick={exportToCSV}>Exporter les données </button>
            </div>
            <div className="search filter-items" id={`${classSeeMore}`}>
                <Link to={seeMore} > Voir Plus </Link>
            </div>
            <div className='list-elements-details'>
                {doubleFilteredItems.map((item, index) => (
                    <div className='list-elements custom-list-details' key={index}>
                        <ul>
                            {
                                item[key1]?.includes(":") ?
                                    <li>{formattedDate(item[key1])}</li>
                                    :
                                    <li>{item[key1]}</li>
                            }
                            <li>{item[key2]}</li>
                            <li>{item[key3]}</li>
                            <li>{item[key4]}</li>
                            {item[key5] && item[key5] !== "Not Validate" && item[key5] !== "Validate" && typeof (item[key5]) === "string" ?
                                <li>{convertChar(item[key5])}</li>
                                :
                                <li>{item[key5]}</li>
                            }
                        </ul>
                        <div className='actions'>
                            <Link to={`${linkList.show}/${item[idKey]}`}>
                                <img src={show} alt="show-icon" />
                            </Link>
                            <Link to={`${linkList.edit}/${item[idKey]}`}>
                                <img src={edit} alt="edit-icon" />
                            </Link>
                            <Link to="#">
                                <img src={iconDelete} onClick={() => deleteProduct(item[idKey], urlDelete)} alt="delete-icon" />
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CustomList;
