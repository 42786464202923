import React, { useState } from "react";
import Instance from "../../Services/Instance";
import config from "../../Services/config.js";
import SideBar from "../../Layouts/SideBar";
import "../../Assets/Styles/Products.css";
import "../../Assets/Styles/Blog.css";

function NewPost() {
    const [error, setError] = useState("");
    const [newPost, setNewPost] = useState({
        title: "",
        content: "",
        category: "",
        introduction: "", // Introduction ajoutée
        productImage: null, // Change to null for File
    });
    const [subsections, setSubsections] = useState([{ subtitle: "", content: "" }]);

    const { title, content, category, productImage, introduction } = newPost;

    const onInputChange = (e) => {
        setNewPost({ ...newPost, [e.target.name]: e.target.value });
    };

    const onImageChange = (e) => {
        setNewPost({ ...newPost, productImage: e.target.files[0] });
    };

    // Gestion des sous-parties
    const handleSubsectionChange = (index, e) => {
        const updatedSubsections = subsections.map((subsection, i) =>
            index === i ? { ...subsection, [e.target.name]: e.target.value } : subsection
        );
        setSubsections(updatedSubsections);
    };

    const addSubsection = () => {
        setSubsections([...subsections, { subtitle: "", content: "" }]);
    };

    const removeSubsection = (index) => {
        const updatedSubsections = subsections.filter((_, i) => i !== index);
        setSubsections(updatedSubsections);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('content', content);
            formData.append('category', category);
            formData.append('introduction', introduction); // Ajouter l'introduction
            formData.append('productImage', productImage);

            // Ajouter chaque sous-partie à la requête
            formData.append('subsections', JSON.stringify(subsections));

            await Instance.post(`${config.api_url}/new-post`, formData);
            window.location = "/dashboard/blog";
        } catch (error) {
            console.error("-+-+-+-Erreur lors de la creation de post:", error);
            setError(error);
        }
    };

    return (
        <div className='a-new-product'>
            <SideBar />
            <div className="parent-new-product">
                <form onSubmit={(e) => handleSubmit(e)} className="new-product custom-layout-product" encType="multipart/form-data">
                    <div className="form-display ">
                        <div>
                            <label htmlFor="title">Title</label>
                            <input
                                className="input"
                                value={title}
                                name="title"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>

                        <div>
                            <label htmlFor="category">Category</label>
                            <input
                                className="input"
                                value={category}
                                name="category"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>

                        <div>
                            <label htmlFor="image">Image</label>
                            <input
                                className="input"
                                type="file"
                                name="productImage"
                                onChange={(e) => onImageChange(e)}
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="introduction">Introduction</label>
                            <textarea
                                className="input"
                                value={introduction}
                                name="introduction"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>

                        <div id="description">
                            <label htmlFor="content">Content</label>
                            <textarea
                                className="input"
                                value={content}
                                name="content"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="faq-new-product">
                        <h3>Sous-Parties</h3>
                        {subsections.map((subsection, index) => (
                            <div key={index} className="subsection">

                                <div>
                                    <label htmlFor="subtitle">{`Sous-Titre ${index + 1}`}</label>
                                    <input
                                        className="input"
                                        type="text"
                                        name="subtitle"
                                        value={subsection.subtitle}
                                        onChange={(e) => handleSubsectionChange(index, e)}
                                        required
                                    />
                                </div>
                                <div id="description">
                                    <label htmlFor="content">{`Contenu ${index + 1}`}</label>
                                    <textarea
                                        className="input"
                                        name="content"
                                        value={subsection.content}
                                        onChange={(e) => handleSubsectionChange(index, e)}
                                        required
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="more-faq"                                    onClick={() => removeSubsection(index)}
                                >
                                    Supprimer
                                </button>
                            </div>
                        ))}
                        <button type="button" className="more-faq" onClick={addSubsection}>
                            + sous-partie
                        </button>
                    </div>

                    <div className="validate">
                        <button type="submit" className="add-new-element">Ajouter</button>
                    </div>
                </form>
            </div>
            {error && (
                <h1 style={{ color: "red", position: "fixed", left: "10%", bottom: "10%" }}>{error.response.data.message}</h1>
            )}
        </div>
    );
}

export default NewPost;
