import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Instance from '../Services/Instance';
import config from "../Services/config.js";
import show from "../Assets/Imgs/icon-show.svg";
import edit from "../Assets/Imgs/icon-edit.svg";
import iconDelete from "../Assets/Imgs/icon_delete.svg";
import "../Assets/Styles/Dasboard.css";
import logo from "../Assets/Imgs/senpremiumlogo2 3.svg";

const CustomFilterItems = (props) => {
    const { barParams, listItems, linkList, key1, key2, key3, key4, key5, idKey, urlDelete, seeMore, classSeeMore } = props;

    const [searchTerm, setSearchTerm] = useState("");
    const [searchTerm2, setSearchTerm2] = useState("");
    
    // Ajout des états pour jour, mois et année
    const [searchDay, setSearchDay] = useState("");
    const [searchMonth, setSearchMonth] = useState("");
    const [searchYear, setSearchYear] = useState("");

    async function deleteProduct(id, url) {
        const confirmation = window.confirm("Voulez-vous vraiment supprimer cet élément ?");
        if (!confirmation) {
            return;
        }

        try {
            await Instance.delete(`${config.api_url}/${url}/${id}`);
            window.location.reload();
        } catch (error) {
            console.error("Erreur lors de la suppression de l'élément :", error);
        }
    }

    const formattedDate = (_date) => {
        const dateObject = new Date(_date);
        return dateObject.toLocaleDateString('fr-FR');
    };

    const isValidDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        const date = new Date(`${year}-${month}-${day}`);
        return date instanceof Date && !isNaN(date);
    };

    const formatDate = (date) => {
        const d = new Date(date);
        let day = d.getDate();
        let month = d.getMonth() + 1;
        const year = d.getFullYear();

        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return `${day}/${month}/${year}`;
    };

    // Filtrage avec les critères de jour, mois, année
    const filteredItems = listItems.filter(item => {
        const itemDate = new Date(item[key1]);
        const itemDay = itemDate.getDate();
        const itemMonth = itemDate.getMonth() + 1;
        const itemYear = itemDate.getFullYear();

        const matchDay = searchDay ? itemDay === parseInt(searchDay) : true;
        const matchMonth = searchMonth ? itemMonth === parseInt(searchMonth) : true;
        const matchYear = searchYear ? itemYear === parseInt(searchYear) : true;

        return matchDay && matchMonth && matchYear;
    });

    // Deuxième filtrage avec searchTerm2 sur les résultats filtrés précédemment
    const doubleFilteredItems = filteredItems.filter(item => {  
        const search2 = searchTerm2.toLowerCase();

        return (
            item[key1]?.toString().toLowerCase().includes(search2) ||
            item[key2]?.toString().toLowerCase().includes(search2) ||
            item[key3]?.toString().toLowerCase().includes(search2) ||
            item[key4]?.toString().toLowerCase().includes(search2) ||
            (item[key5] && item[key5].toString().toLowerCase().includes(search2))
        );
    });

    return (
        <div className='custom-list'>
            <div className='list-elements nav-bar-list'>
                <ul>
                    <li>{barParams.nav1}</li>
                    <li>{barParams.nav2}</li>
                    <li>{barParams.nav3}</li>
                    <li>{barParams.nav4}</li>
                    <li>{barParams.nav5}</li>
                </ul>
                <div className='actions'>
                    <p>Actions</p>
                </div>
            </div>
            <div className="filter-search">
                {/* Filtres de recherche par jour, mois et année */}
                <div className='logo-side-bar' id='logo-details-subs'>
                    <Link to="/dashboard" >
                        <img src={logo} alt='logo' />
                    </Link>
                </div>                <input
                    type="number"
                    placeholder="Jour"
                    value={searchDay}
                    onChange={(e) => setSearchDay(e.target.value)}
                />
                <input
                    type="number"
                    placeholder="Mois"
                    value={searchMonth}
                    onChange={(e) => setSearchMonth(e.target.value)}
                />
                <input
                    type="number"
                    placeholder="Année"
                    value={searchYear}
                    onChange={(e) => setSearchYear(e.target.value)}
                />
                {/* Recherche textuelle */}
                <input
                    type="text"
                    placeholder='Recherche...'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <input
                    type="text"
                    placeholder='Recherche...'
                    value={searchTerm2}
                    onChange={(e) => setSearchTerm2(e.target.value)}
                />
            </div>
            <div className="search filter-items" id={`${classSeeMore}`}>
                <Link to={seeMore}>Voir Plus</Link>
            </div>
            <div className='list-elements-details'>
                {doubleFilteredItems.map((item, index) => (
                    <div className='list-elements custom-list-details' key={index}>
                        <ul>
                            <li>{formattedDate(item[key1])}</li>
                            <li>{item[key2]}</li>
                            <li>{item[key3]}</li>
                            <li>{item[key4]}</li>
                            {item[key5] && <li>{item[key5]}</li>}
                        </ul>
                        <div className='actions'>
                            <Link to={`${linkList.show}/${item[idKey]}`}>
                                <img src={show} alt="show-icon" />
                            </Link>
                            <Link to={`${linkList.edit}/${item[idKey]}`}>
                                <img src={edit} alt="edit-icon" />
                            </Link>
                            <Link to="#">
                                <img src={iconDelete} onClick={() => deleteProduct(item[idKey], urlDelete)} alt="delete-icon" />
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CustomFilterItems;
