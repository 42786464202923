import React, { useContext } from 'react';
import { DataContext } from '../Services/DataContext';
import MainLayout from '../Layouts/MainLayout';
import SideBar from '../Layouts/SideBar';
import CustomList from '../Layouts/CustomList';
import CustomFilterItems from '../Layouts/CustomFilterItems';
// import { useParams } from 'react-router-dom';

function SeeMorSubscriptions() {

    const { allSubscriptions } = useContext(DataContext);
    const barParams = { nav1: 'Date', nav2: 'Nom Produit', nav3: 'N° Commande', nav4: 'Prix', nav5 : "Status" };
    const titleItem = { name: "Co-abonnements", title1: "Subscription", title2: "Nombre de Subscription" , title3 : "Subscription", title4 : "Subscription"}
    const linkItem = {  link1: "#", link2: "#" , link3 : "#", link4 : "#"}
    const linkList = { show : `/dashboard/subscription/show-subscription-id` ,edit : "/dashboard/subscription/edit-subscription"}
    

    const filterDuplicates = (array) => {
        // Créer un objet de hachage pour stocker les clés uniques
        const hash = {};
        // Filtrer les éléments en utilisant l'objet de hachage pour garder un seul élément par clé unique
        const filteredArray = array.filter(item => {
            if (!hash[item.subscriptionRef]) {
                hash[item.subscriptionRef] = true;
                return true;
            }
            return false;
        });
        return filteredArray;
    };
    
    const SubsValidate = allSubscriptions.filter((sub) => sub.subscriptionStatus === "Validate")

    const filteredArray = filterDuplicates(SubsValidate);


    return (
        <div className='Product-layout'>
        
            <MainLayout 
                titleItem={titleItem}
                linkItem = {linkItem}
                numberItem ={filteredArray.length}

            />
            <CustomFilterItems
                barParams={barParams}
                listItems={[...filteredArray].reverse()}
                linkList = {linkList}
                key1="createdAt"
                key2="subscriptionName"
                key3="subscriptionRef"
                key4="subscriptionPrice"
                key5="subscriptionStatus"
                idKey = "_id"
                urlDelete = "delete-subscription"

            />
        </div>
    );
}

export default SeeMorSubscriptions;