import { useState, useEffect, useContext } from "react";
import Instance from "../../Services/Instance";
import config from "../../Services/config.js";
import SideBar from "../../Layouts/SideBar";
import "../../Assets/Styles/Products.css";
import AddCategoryOrProduct from "./AddCategoryOrProduct.js";
import { DataContext } from '../../Services/DataContext.js';

function NewProduct() {
    const [error, setError] = useState("");
    const [newProduct, setNewProduct] = useState({
        productCategory: "",
        productName: "",
        productType: "",
        productImage: null,
        productDescription: "",
        productPrice: "",
        productPrice2: "",
        productAttention: "",
        points: "",
        faq: [{ question: "", answer: "" }]
    });

    const { categories } = useContext(DataContext);
    const { productCategory, productName, productType, productImage, productDescription, productPrice, productPrice2, productAttention,points, faq } = newProduct;

    const onInputChange = (e) => {
        setNewProduct({ ...newProduct, [e.target.name]: e.target.value });
    };

    const onImageChange = (e) => {
        setNewProduct({ ...newProduct, productImage: e.target.files[0] });
    };

    const handleFaqChange = (index, fieldName, value) => {
        const updatedFaqs = [...newProduct.faq];
        updatedFaqs[index][fieldName] = value;
        setNewProduct({ ...newProduct, faq: updatedFaqs });
    };

    const addFaq = () => {
        setNewProduct({ ...newProduct, faq: [...faq, { question: "", answer: "" }] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('productCategory', productCategory);
            formData.append('productName', productName);
            formData.append('productType', productType);
            formData.append('productImage', productImage);
            formData.append('productDescription', productDescription);
            formData.append('productPrice', parseFloat(productPrice) || 0);
            formData.append('productPrice2', parseFloat(productPrice2) || 0);
            formData.append('points', parseFloat(points) || 0);
            formData.append('productAttention', productAttention);

            faq.forEach((_faq, index) => {
                formData.append(`faq[${index}][question]`, _faq.question);
                formData.append(`faq[${index}][answer]`, _faq.answer);
            });

            // Log formData entries for debugging
            for (let [key, value] of formData.entries()) {
                // console.log(key, value);
            }

            await Instance.post(`${config.api_url}/new--product`, formData);
            window.location = "/dashboard/product";
        } catch (error) {
            console.error("Error during submission:", error);
            setError(error);
        }
    };

    return (
        <div className='a-new-product'>
            <SideBar />
            <div className="parent-new-product">
                <div className="new-category">
                    <AddCategoryOrProduct />
                </div>
                <form onSubmit={handleSubmit} className="new-product" encType="multipart/form-data">
                    <h1>Ajout d'un nouveau produit</h1>
                    <div className="form-display">
                        <div>
                            <label>Category:</label>
                            <select
                                value={productCategory}
                                name="productCategory"
                                onChange={onInputChange}
                                required
                            >
                                <option value="">Select a Category</option>
                                {categories && categories.map((item, index) => (
                                    <option key={index} value={item.categoryName}>{item.categoryName}</option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label>Product:</label>
                            <select
                                value={productName}
                                name="productName"
                                onChange={onInputChange}
                                required
                                disabled={!productCategory}
                            >
                                <option value="">Select a product</option>
                                {productCategory &&
                                    categories
                                        .find((item) => item.categoryName === productCategory)
                                        ?.productsName?.map((product, index) => (
                                            <option key={index} value={product}>{product}</option>
                                        ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="productType">Product Type</label>
                            <input
                                className="input"
                                value={productType}
                                name="productType"
                                type="text"
                                onChange={onInputChange}
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="productImage">Product Image</label>
                            <input
                                className="input"
                                type="file"
                                name="productImage"
                                onChange={onImageChange}
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="productPrice">Product Price</label>
                            <input
                                className="input"
                                value={productPrice}
                                name="productPrice"
                                type="text"
                                onChange={onInputChange}
                                required
                            />
                        </div>

                        {/*<div>
                            <label htmlFor="points">Points pour ce produit</label>
                            <input
                                className="input"
                                value={points}
                                name="points"
                                type="number"
                                onChange={onInputChange}
                                required
                            />
                        </div> */}

                        {productName.toLocaleLowerCase().includes("netflix") && (
                            <div>
                                <label htmlFor="productPrice2">Prix Smart TV </label>
                                <input
                                    className="input"
                                    value={productPrice2}
                                    name="productPrice2"
                                    type="text"
                                    onChange={onInputChange}
                                />
                            </div>
                        )}

                        <div id="description">
                            <label htmlFor="productDescription">Product Description</label>
                            <textarea
                                className="input"
                                value={productDescription}
                                name="productDescription"
                                onChange={onInputChange}
                                required
                            />
                        </div>
                        <div id="description">
                            <label htmlFor="productDescription">Text d'avertisement</label>
                            <textarea
                                className="input"
                                value={productAttention}
                                name="productAttention"
                                onChange={onInputChange}
                            />
                        </div>
                    </div>
                    <div className="faq-new-product">
                        <h1>FAQs</h1>
                        {faq.map((faq_, index) => (
                            <div key={index} className="faq-content">
                                <h4>{`FAQ ${index + 1}`}</h4>
                                <div>
                                    <label htmlFor={`question-${index}`}>{`Question ${index + 1}:`}</label>
                                    <input
                                        type="text"
                                        id={`question-${index}`}
                                        value={faq_.question}
                                        onChange={(e) => handleFaqChange(index, 'question', e.target.value)}
                                    />
                                </div>
                                <div id="description">
                                    <label htmlFor={`answer-${index}`}>{`Answer ${index + 1}:`}</label>
                                    <textarea
                                        id={`answer-${index}`}
                                        value={faq_.answer}
                                        onChange={(e) => handleFaqChange(index, 'answer', e.target.value)}
                                    />
                                </div>
                            </div>
                        ))}
                        <div>
                            <button className="more-faq" type="button" onClick={addFaq}>Plus de FAQs</button>
                        </div>
                    </div>
                    <div className="validate">
                        <button type="submit" className="add-new-element">Add</button>
                    </div>
                </form>
            </div>
            {error && (
                <h1 style={{ color: "red", position: "fixed", left: "10%", bottom: "10%" }}>
                    {error.response?.data?.message || "An error occurred"}
                </h1>
            )}
        </div>
    );
}

export default NewProduct;
