import React, { useContext } from 'react';
import { DataContext } from '../Services/DataContext';
import MainLayout from '../Layouts/MainLayout';
import SideBar from '../Layouts/SideBar';
import CustomList from '../Layouts/CustomList';

function User() {

    const { users } = useContext(DataContext);
    const barParams = { nav1: 'First Name', nav2: 'Last Name', nav3: 'Email' , nav4: 'N° téléphone'};
    const titleItem = { name: "Users", title1: "Users", title2: "Users" , title3 : "Users", title4 : "Users"}
    const linkItem = {  link1: "#", link2: "#" , link3 : "#", link4 : "#"}
    const linkList = { show : `/dashboard/user/show-user` ,edit : `/dashboard/user/edit-user`}
    // console.log(products);
    return (
        <div className='Product-layout'>
            <SideBar />
            <MainLayout 
                titleItem={titleItem}
                linkItem = {linkItem}
                numberItem ={users.length}

            />
            <CustomList
                barParams={barParams}
                listItems={[...users].reverse()}
                linkList = {linkList}
                key1="firstName"
                key2="lastName"
                key3="email"
                key4="numberPhone"
                idKey = "_id"
                urlDelete = "delete-user"
                btnExportData = "btn-export-data"


            />
        </div>
    );
}

export default User;
