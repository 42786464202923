import { useState, useContext } from "react";
import Instance from "../../Services/Instance";
import config from "../../Services/config.js";
import SideBar from "../../Layouts/SideBar";
import "../../Assets/Styles/Products.css";
import "../../Assets/Styles/Licenses.css";
import { DataContext } from '../../Services/DataContext.js';

function NewLicense() {
    const [error, setError] = useState("");
    const [formData, setFormData] = useState({
        name: "",
        productTypeSmart: "",
        key: "",
        number: "",
        status: "available",
        policy: "",
        creationDate: Date.now(),
        productCategory: "",
        productName: "",
        productType: "",
    });
    const { productCategory, productTypeSmart, productName } = formData;
    const { categories } = useContext(DataContext);

    const handleInputChange = (e) => {
        setFormData({
            ...formData, [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await Instance.post(`${config.api_url}/new-license`, formData);
            window.location = "/dashboard/Licenses";
        } catch (error) {
            console.error("-+-+-+-Erreur lors de l'ajout:", error);
            setError(error);
        }
    };
console.log(categories);

    return (
        <div className='a-new-product'>
            <SideBar />
            <div className="parent-new-product">
                <form onSubmit={handleSubmit} className="new-product custom-layout-product" encType="multipart/form-data">
                    <div className="form-display ">
                        <div>
                            <label>Category:</label>
                            <select
                                value={productCategory}
                                name="productCategory"
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select a Category</option>
                                {categories && Array.isArray(categories) && categories.map((item, index) => (
                                    <option key={index} value={item.categoryName}>{item.categoryName}</option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label>Product:</label>
                            <select
                                value={productName}
                                name="productName"
                                onChange={handleInputChange}
                                required
                                disabled={!productCategory}
                            >
                                <option value="">Select a product</option>
                                {productCategory && categories && Array.isArray(categories) &&
                                    categories
                                        .find((item) => item.categoryName === productCategory)
                                        ?.productsName.map((product, index) => (
                                            <option key={index} value={product}>{product}</option>
                                        ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="productType">Product Type</label>
                            <input
                                className="input"
                                value={formData.productType}
                                name="productType"
                                type="text"
                                onChange={handleInputChange}
                            />
                        </div>

                        {productName && productName.toLocaleLowerCase().includes("netflix") &&
                            <div>
                                <label>Netflix type:</label>
                                <select
                                    value={productTypeSmart}
                                    name="productTypeSmart"
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select a Netflix type</option>
                                    <option value="Smart TV">Smart TV</option>
                                    <option value="Phone/Pc">Phone/Pc</option>

                                </select>
                            </div>}

                        <div>
                            <label htmlFor="productType">License Name</label>
                            <input
                                className="input"
                                value={formData.name}
                                name="name"
                                type="text"
                                onChange={handleInputChange}
                            />
                        </div>

                        <div>
                            <label>Account Number</label>
                            <input type="number" className="input" name="number" value={formData.number} onChange={handleInputChange} required />
                        </div>
                        <div>
                            <label htmlFor="key">Key</label>
                            <textarea name="key" className="textarea" value={formData.key} onChange={handleInputChange} required />
                        </div>

                        <div>
                            <label>Policy</label>
                            <input type="text" className="input" name="policy" value={formData.policy} onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className="validate">
                        <button type="submit" className="add-new-element">Add</button>
                    </div>
                </form>
            </div>
            {error && (
                <h1 style={{ color: "red", position: "fixed", left: "10%", bottom: "10%" }}>{error.response.data.message}</h1>
            )}
        </div>
    );
}

export default NewLicense;
